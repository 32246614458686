import React from "react"
import img from "../../../images/projetos/aprt/elsa/Plantas/T4-N .jpg";


export default function Home() {
  return (
    <div style={{backgroundColor:"black", textAlign:"center"}}>
        <a href={img} download style={{color:"white", fontSize:"25px"}}>
        <img src={img} style={{width:"100%"}}/>
        </a>
    </div>
  );
}